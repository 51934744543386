/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';

import { history } from 'store/ConfigureStore';

import ButtonSection from 'components/ButtonSection';
import CardSmall from 'components/CardSmall';
import { AUTOGESTION_IDP, MFA_PAGE_ROUTE } from 'constants/RouterConstants';
import { startConfiguringSecondFactor } from 'actions/seguridad';

const EnforceSecondFactor = () => {
  const dispatch = useDispatch();

  const [redirectUri, setRedirectUri] = useState();
  const [fechaSegundoFactor, setFechaSegundoFactor] = useState();

  useEffect(() => {
    const { redirect_uri, state, fecha_segundo_factor } = queryString.parse(
      history.location.search,
    );
    if (redirect_uri && state) setRedirectUri(`${redirect_uri}&state=${state}`);
    if (fecha_segundo_factor) setFechaSegundoFactor(fecha_segundo_factor);
    history.replace(history.location.pathname);
  }, [history]);

  const handleConfigurar = () => {
    if (redirectUri) {
      dispatch(startConfiguringSecondFactor(redirectUri));
    }
    history.push(MFA_PAGE_ROUTE);
  };

  const handleRechazar = () => {
    if (redirectUri) window.location.href = redirectUri; // caso SP
    else history.push(AUTOGESTION_IDP); // caso sin SP
  };

  return (
    <CardSmall
      title="Advertencia"
      desc={`El día ${fechaSegundoFactor} va a forzarse el segundo factor de autenticación, no lo tienes activado, presionando debajo puedes activarlo`}
    >
      <ButtonSection
        textSecondary="No configurar"
        ariaLabelSecondary="no configurar"
        handleClickSecondary={handleRechazar}
        textPrimary="Configurar 2do factor"
        ariaLabelPrimary="configurar 2do factor"
        handleClickPrimary={handleConfigurar}
        style={{
          margin: '24px auto 0',
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: '10px',
        }}
      />
    </CardSmall>
  );
};

export default EnforceSecondFactor;
