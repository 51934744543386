/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import show_password from 'assets/icons/show_password.svg';
import hide_password from 'assets/icons/hide-password.svg';
import { failSimilarity } from 'utils/validators';

import {
  CONTRASENA_INVALIDA,
  REQUERIDO_VALIDADOR_MENSAJE,
  getMensajeErrorLargoMinimo,
  VALIDADOR_BUENO,
  VALIDADOR_TIPO_ERROR,
  VALIDADOR_VALOR_BUENO,
  VALIDADOR_TIPO_OK,
  VALIDADOR_VALOR_ERROR,
  VALIDADOR_VALOR_EXCELENTE,
  VALIDADOR_EXCELENTE,
  VALIDADOR_AVISO,
  VALIDADOR_VALOR_AVISO,
  VALIDADOR_TIPO_AVISO,
  DATO_REQUERIDO,
} from 'constants/commonConstants';

import PasswordWidget from './PasswordWidget';

import styles from './styles.module.css';

export const PasswordFieldWithWidget = ({
  register,
  trigger,
  name,
  label,
  required,
  minLength,
  validationRegex,
  regexErrorMessage,
  value,
  getValues,
  setFocus,
  validationErrorMessage,
  error,
  touched,
  addInvalidField,
  removeInvalidField,
}) => {
  const [showWidget, setShowWidget] = useState(false);
  const [showTips, setShowTips] = useState(false);
  const [claseLabel, setClaseLabel] = useState('form-field__label');
  const [claseInput, setClaseInput] = useState('form-field__input');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [imgButton, setImgButton] = useState(show_password);
  const [passwordFeedback, setPasswordFeedback] = useState({
    message: REQUERIDO_VALIDADOR_MENSAJE,
    severity: 0,
  });

  const validations = {
    required,
    minLength,
    pattern: validationRegex,
    // eslint-disable-next-line no-shadow
    validate: value => !failSimilarity(value, getValues()),
  };

  const handleFocus = () => {
    setShowWidget(true);
  };

  const handleBlur = e => {
    if (
      e.relatedTarget &&
      e.relatedTarget.className.includes('password-field__popup__tips__link')
    ) {
      e.preventDefault();
      setFocus();
    } else {
      setShowWidget(false);
      setShowTips(false);
    }
  };

  const handleShowTips = () => {
    setShowTips(true);
  };

  const togglePassword = () => {
    setImgButton(showPassword ? show_password : hide_password);
    setShowPassword(!showPassword);
  };

  const getNonErrorFeedback = () => {
    if (value && value.length === 8) {
      return {
        message: VALIDADOR_AVISO,
        severity: VALIDADOR_VALOR_AVISO,
        className: VALIDADOR_TIPO_AVISO,
      };
    }
    if (value && value.length > 8 && value.length < 16)
      return {
        message: VALIDADOR_BUENO,
        severity: VALIDADOR_VALOR_BUENO,
        className: VALIDADOR_TIPO_OK,
      };
    if (value && value.length >= 16)
      return {
        message: VALIDADOR_EXCELENTE,
        severity: VALIDADOR_VALOR_EXCELENTE,
        className: VALIDADOR_TIPO_OK,
      };
    return {
      message: REQUERIDO_VALIDADOR_MENSAJE,
      severity: 0,
    };
  };

  useEffect(() => {
    if (touched) trigger('repetirPassword');
  }, [value]);

  useEffect(() => {
    if (error) {
      if (addInvalidField) addInvalidField(name);
      if (touched) {
        setClaseLabel('form-field__label--error');
        setClaseInput('form-field__input--error');
      }
      // eslint-disable-next-line default-case
      switch (error.type) {
        case 'required':
          setErrorMessage(DATO_REQUERIDO);
          setPasswordFeedback({
            message: REQUERIDO_VALIDADOR_MENSAJE,
            severity: 0,
          });
          break;
        case 'minLength':
          if (touched) setErrorMessage(CONTRASENA_INVALIDA);
          setPasswordFeedback({
            message: getMensajeErrorLargoMinimo(value),
            severity: VALIDADOR_VALOR_ERROR,
            className: VALIDADOR_TIPO_ERROR,
          });
          break;
        case 'pattern':
          if (touched) setErrorMessage(CONTRASENA_INVALIDA);
          setPasswordFeedback({
            message: regexErrorMessage,
            severity: VALIDADOR_VALOR_ERROR,
            className: VALIDADOR_TIPO_ERROR,
          });
          break;
        case 'validate':
          if (touched) setErrorMessage(CONTRASENA_INVALIDA);
          setPasswordFeedback({
            message: validationErrorMessage,
            severity: VALIDADOR_VALOR_ERROR,
            className: VALIDADOR_TIPO_ERROR,
          });
          break;
      }
    } else {
      if (removeInvalidField) removeInvalidField(name);
      setPasswordFeedback(getNonErrorFeedback());
      setClaseInput('form-field__input');
      setClaseLabel('form-field__label');
    }
  }, [error, value]);

  return (
    <div className={styles['form-field']}>
      <span>
        <label className={styles[claseLabel]} htmlFor={name}>
          {label}
        </label>
      </span>
      <div className={styles['form-field__input-password__container']}>
        <input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register(name, {
            ...validations,
            onBlur: handleBlur,
            // se sobreescribe la validación onTouch para
            // mostrar información al instante en el tooltip.
            onChange: () => {
              trigger('password');
            },
          })}
          type={showPassword ? 'text' : 'password'}
          id={name}
          onFocus={handleFocus}
          className={styles[claseInput]}
        />
        {showWidget && (
          <div style={{ position: 'relative' }}>
            <PasswordWidget
              message={passwordFeedback.message}
              barWidth={passwordFeedback.severity}
              className={passwordFeedback.className}
              showTips={showTips}
              handleShowTips={handleShowTips}
            />
          </div>
        )}
        <button
          type="button"
          className={styles['form-field__input-password__button']}
          onClick={togglePassword}
        >
          <img alt="Mostrar contraseña" src={imgButton} />
        </button>
      </div>
      {error && (
        <div className={styles['form-field__help-text__error__div']}>
          <span className={styles['form-field__help-text__error']}>
            {errorMessage}
          </span>
        </div>
      )}
    </div>
  );
};

PasswordFieldWithWidget.propTypes = {
  register: PropTypes.func.isRequired,
  trigger: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  minLength: PropTypes.number,
  validationRegex: PropTypes.shape(RegExp),
  regexErrorMessage: PropTypes.string,
  value: PropTypes.string,
  getValues: PropTypes.func,
  setFocus: PropTypes.func,
  validationErrorMessage: PropTypes.string,
  error: PropTypes.object,
  touched: PropTypes.bool,
  addInvalidField: PropTypes.func,
  removeInvalidField: PropTypes.func,
};

export default PasswordFieldWithWidget;
