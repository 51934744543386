import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Helmet } from 'react-helmet';

import * as ROUTES from 'constants/RouterConstants';
import { LANDING_HOME_TITLE } from 'constants/pageTitles';
import LogoutProcessContainer from 'containers/LogoutProcessContainer';
import LogoutContainer from 'containers/LogoutContainer';
import ReenvioActivacionSuccess from 'containers/ReenvioActivacionSuccess/ReenvioActivacionSuccess';
import AriaLiveContainer from 'containers/AriaLiveContainer';
import SAMLIndexContainer from 'containers/SAMLIndexContainer/SAMLIndexContainer';
import SAMLIndexContainerViejo from 'containers/SAMLIndexContainerViejo';
import SAMLLogoutContainer from 'containers/SAMLLogoutContainer';
import { history } from 'store/ConfigureStore';
import scrollToTop from 'enhancers/scrollToTop';
import { PERMISO_TRAMITAR_VIDEOLLAMADA } from 'constants/permissionsConstants';
import { condicionPantallaIngresoVideollamada } from 'constants/conditionConstants';

import SeguridadContainer from 'screens/seguridad/SeguridadContainer';
import AuthContraseñaContainer from 'screens/AuthContrasena/AuthContrasenaContainer';
import AuthContraseñaContainerViejo from 'screens/auth_contrasena/AuthContrasenaContainerViejo';
import CambioContraseñaContainer from 'screens/cambio_contraseña/CambioContraseñaContainer';
import CambioContraseñaSuccess from 'screens/cambio_contraseña_success/CambioContraseñaSuccessContainer';
import CambioEmailContainer from 'screens/cambio_email/CambioEmailContainer';
import CambioEmailApproveContainer from 'screens/cambio_email_approve/CambioEmailApproveContainer';
import CambioCorreoSuccess from 'screens/cambio_email_success/CambioEmailSuccessContainer';
import CambioEmailValidatedContainer from 'screens/cambio_email_validated/CambioEmailValidatedContainer';
import CambioEmailErrorContainer from 'screens/cambio_email_error/CambioEmailErrorContainer';
import AyudaContainer from 'screens/ayuda/AyudaContainer';
import AyudaItemDetailContainer from 'screens/ayuda_item_detail/AyudaItemDetailContainer';
import ErrorPageContainer from 'screens/error_page/ErrorPageContainer';
import {
  AcercaDeContainer,
  TermAndCondContainer,
  PrivacidadContainer,
  MetodosDisponiblesContainer,
} from 'screens/paginas_estaticas/PaginasEstaticasContainer';
import RegistroContainer from 'screens/registro_formulario/RegistroContainer';
import RegistroMailEnviadoContainer from 'screens/registro_mail_enviado/RegistroMailEnviadoContainer';
import RegistroResendedMailContainer from 'screens/registro_mail_reenviado/RegistroResendedMailContainer';
import RegistroActivateAccountContainer from 'screens/registro_activar_cuenta/RegistroActivateAccountContainer';
import RegistroSuccessContainer from 'screens/registro_cuenta_activada/RegistroSuccessContainer';
import RegistroErrorContainer from 'screens/registro_link_invalido/RegistroErrorContainer';
import RegistroResendMailContainer from 'screens/registro_reenviar_mail/RegistroResendMailContainer';
import ResetPasswordContainer from 'screens/reset_password_formulario/ResetPasswordContainer';
import ResetPasswordSentContainer from 'screens/reset_password_mail_enviado/ResetPasswordSentContainer';
import NuevaContrasenaContainer from 'screens/NuevaContrasena/NuevaContrasena';
import ResetPasswordLinkInvalidoContainer from 'screens/reset_password_link_invalido/ResetPasswordLinkInvalidoContainer';
import ContactoContainer from 'screens/contacto/ContactoContainer';
import ContactoVideollamadaContainer from 'screens/contacto_videollamada/ContactoVideollamada';
import ContactoEnviadoContainer from 'screens/contacto-enviado/ContactoEnviadoContainer';
import LandingContainer from 'screens/landing/LandingContainer';
import LoginContainer from 'screens/login/LoginContainer';
import ConsentContainer from 'screens/consent/ConsentContainer';
import MultiFactorAuthContainer from 'screens/totp_configurar/MultiFactorAuthContainer';
import TOTPInfoContainer from 'screens/totp_configurar_app/TOTPInfoContainer';
import TOTPVerificarCodigoContainer from 'screens/totp_verificar_codigo/TOTPVerificarCodigoContainer';
import TOTPVerificarCodigoExitoContainer from 'screens/totp_configuracion_exitosa/TOTPVerificarCodigoExitoContainer';
import FeedbackContainer from 'screens/feedback/FeedbackContainer';
import FeedbackFormSentContainer from 'screens/feedback-form-enviado/FeedbackFormSentContainer';
import ContenedorVideollamada from 'screens/videollamada';
import CancelarReserva from 'screens/videollamada/CancelarReserva';
import ConfirmarCancelacionReserva from 'screens/videollamada/ConfirmarCancelacionReserva';
import AceptarElevacionVideollamada from 'screens/videollamada/AceptarElevacionVideollamada/AceptarElevacionVideollamada';
import IngresoVideollamada from 'screens/ingreso_videollamada/ingresoVideollamada';
import { IngresoVideollamadaSoporte } from 'screens/ingreso_videollamada_soporte/ingresoVideollamadaSoporte';
import CambioNumeroTelefonoContainer from 'screens/cambio_numero_telefono/CambioNumeroTelefonoContainer';
import EnforceSecondFactor from 'screens/enforce_second_factor/EnforceSecondFactor';
import { ciudadanoAutenticado } from 'enhancers/ciudadanoAutenticado';
import ciudadanoSinAutenticarse from 'enhancers/ciudadanoSinAutenticarse';
import SAMLResponseContainer from 'containers/SAMLResponseContainer/SAMLResponseContainer';
import AutogestionIdp from 'screens/AutogestionIdp/autogestionIdp';
import ConfirmacionConsulta from 'screens/videollamada/ConfirmacionConsulta';
import FooterContainer from './Footer';
import Header from './Header';
import HeaderViejo from './Header (viejo)';
import { AyudaContainer as AyudaContainerNuevo } from './AyudaContainer';

import 'index.module.css';

const App = () => (
  <>
    <Helmet title={LANDING_HOME_TITLE} />
    <div>
      <AriaLiveContainer />
      <ConnectedRouter history={history}>
        <section className="main-container">
          {!window.REACT_APP_FEATURED_FLAG_REFACTORS_HEADER ? (
            <HeaderViejo history={history} />
          ) : (
            <Header />
          )}
          <div className="page-container">
            <Switch>
              {/*  Login */}
              <Route
                exact
                path={ROUTES.INDEX_ROUTE}
                component={scrollToTop(
                  ciudadanoSinAutenticarse(LandingContainer),
                )}
              />
              <Route
                exact
                path={ROUTES.LOGIN_ROUTE}
                component={scrollToTop(
                  ciudadanoSinAutenticarse(LoginContainer),
                )}
              />
              <Route
                exact
                path={ROUTES.AUTORIZACION_ROUTE}
                component={scrollToTop(ciudadanoAutenticado(ConsentContainer))}
              />
              {/*  Logout */}
              <Route
                exact
                path={ROUTES.LOGOUT_PROCESS}
                component={scrollToTop(
                  !window.REACT_APP_FEATURED_FLAG_REFACTORS_LOGOUT_PROCESS_CONTAINER
                    ? LogoutProcessContainer
                    : LogoutContainer,
                )}
              />
              {/*  Seguridad */}
              <Route
                exact
                path={ROUTES.SEGURIDAD_ROUTE}
                component={scrollToTop(
                  ciudadanoAutenticado(SeguridadContainer),
                )}
              />
              {/*  Seguridad IDP */}
              <Route
                exact
                path={ROUTES.AUTOGESTION_IDP}
                component={ciudadanoAutenticado(AutogestionIdp)}
              />
              <Route
                exact
                path={ROUTES.VERIFY_PASSWORD_ROUTE}
                component={scrollToTop(
                  ciudadanoAutenticado(
                    !window.REACT_APP_FEATURED_FLAG_REFACTORS_AUTH_CONTRASENA
                      ? AuthContraseñaContainerViejo
                      : AuthContraseñaContainer,
                  ),
                )}
              />
              <Route
                exact
                path={ROUTES.RESENDED_ACTIVATION_EMAIL_LOGGED}
                component={scrollToTop(
                  ciudadanoAutenticado(ReenvioActivacionSuccess),
                )}
              />
              <Route
                exact
                path={ROUTES.MFA_PAGE_ROUTE}
                component={scrollToTop(
                  ciudadanoAutenticado(MultiFactorAuthContainer),
                )}
              />
              {/*  Ayuda */}
              <Route
                exact
                path={ROUTES.AYUDA_ROUTE}
                component={scrollToTop(
                  window.REACT_APP_FEATURED_FLAG_REFACTORS_AYUDA_CONTAINER
                    ? AyudaContainerNuevo
                    : AyudaContainer,
                )}
              />
              <Route
                exact
                path={ROUTES.AYUDA_DETAIL_ROUTE}
                component={scrollToTop(AyudaItemDetailContainer)}
              />
              {/*  Contacto */}
              <Route
                exact
                path={ROUTES.CONTACTO_ROUTE}
                component={scrollToTop(ContactoContainer)}
              />
              <Route
                exact
                path={ROUTES.ENVIO_MENSAJE_CONTACTO_SUCCESS}
                component={scrollToTop(ContactoEnviadoContainer)}
              />
              <Route
                exact
                path={ROUTES.CONTACTO_VIDEOLLAMADA_ROUTE}
                component={scrollToTop(ContactoVideollamadaContainer)}
              />
              {/* Multi Factor Authentication */}
              <Route
                exact
                path={ROUTES.MFA_CONFIG_TOTP_ROUTE}
                component={scrollToTop(ciudadanoAutenticado(TOTPInfoContainer))}
              />
              <Route
                exact
                path={ROUTES.MFA_VERIFY_CODE_TOTP_ROUTE}
                component={scrollToTop(
                  ciudadanoAutenticado(TOTPVerificarCodigoContainer),
                )}
              />
              <Route
                exact
                path={ROUTES.MFA_VERIFY_CODE_SUCCESS_TOTP_ROUTE}
                component={scrollToTop(
                  ciudadanoAutenticado(TOTPVerificarCodigoExitoContainer),
                )}
              />
              {/*  Cambio de contraseña */}
              <Route
                exact
                path={ROUTES.CHANGE_PASSWORD_ROUTE}
                component={scrollToTop(
                  ciudadanoAutenticado(CambioContraseñaContainer),
                )}
              />
              <Route
                exact
                path={ROUTES.CHANGE_PASSWORD_ROUTE_SUCCESS}
                component={scrollToTop(CambioContraseñaSuccess)}
              />
              {/*  Cambio de correo electrónico */}
              <Route
                exact
                path={ROUTES.CHANGE_USER_EMAIL_ROUTE}
                component={scrollToTop(
                  ciudadanoAutenticado(CambioEmailContainer),
                )}
              />
              <Route
                exact
                path={ROUTES.CHANGE_USER_EMAIL_ROUTE_SUCCESS}
                component={scrollToTop(
                  ciudadanoAutenticado(CambioCorreoSuccess),
                )}
              />
              <Route
                exact
                path={ROUTES.CHANGE_USER_EMAIL_APPROVE_ROUTE}
                component={scrollToTop(CambioEmailApproveContainer)}
              />
              <Route
                exact
                path={ROUTES.CHANGE_USER_EMAIL_VALIDATED_ROUTE}
                component={scrollToTop(CambioEmailValidatedContainer)}
              />
              <Route
                exact
                path={ROUTES.CHANGE_USER_EMAIL_ERROR_ROUTE}
                component={scrollToTop(CambioEmailErrorContainer)}
              />
              {/*  Cambio de número de teléfono */}
              <Route
                exact
                path={ROUTES.CAMBIAR_TELEFONO_ROUTE}
                component={scrollToTop(
                  ciudadanoAutenticado(CambioNumeroTelefonoContainer),
                )}
              />
              {/*  Registro */}
              <Route
                exact
                path={ROUTES.REGISTRO_ROUTE}
                component={scrollToTop(
                  ciudadanoSinAutenticarse(RegistroContainer),
                )}
              />
              <Route
                exact
                path={ROUTES.REGISTRO_SENDED_ACTIVATION_EMAIL}
                component={scrollToTop(
                  ciudadanoSinAutenticarse(RegistroMailEnviadoContainer),
                )}
              />
              <Route
                exact
                path={ROUTES.REGISTRO_REENVIAR_CORREO_ACTIVACION}
                component={scrollToTop(
                  ciudadanoSinAutenticarse(RegistroResendMailContainer),
                )}
              />
              <Route
                exact
                path={ROUTES.REGISTRO_RESENDED_ACTIVATION_EMAIL}
                component={scrollToTop(
                  ciudadanoSinAutenticarse(RegistroResendedMailContainer),
                )}
              />
              <Route
                exact
                path={ROUTES.REGISTRO_ACTIVATE_ACCOUNT}
                component={scrollToTop(RegistroActivateAccountContainer)}
              />
              <Route
                exact
                path={ROUTES.REGISTRO_ACTIVATION_SUCCESS}
                component={scrollToTop(RegistroSuccessContainer)}
              />
              <Route
                exact
                path={ROUTES.REGISTRO_ACTIVATION_ERROR}
                component={scrollToTop(RegistroErrorContainer)}
              />
              {/*  Acerca de */}
              <Route
                exact
                path={ROUTES.ACERCA_DE_ROUTE}
                component={scrollToTop(AcercaDeContainer)}
              />
              {/*  Terminos y condiciones */}
              <Route
                exact
                path={ROUTES.TERMS_N_CONDS_ROUTE}
                component={scrollToTop(TermAndCondContainer)}
              />
              {/*  Declaración de Accesibilidad */}
              {/* <Route exact path={ROUTES.DECLARACION_ACCESIBILIDAD_ROUTE}
                component={scrollToTop(AccesibilidadContainer)} /> */}
              {/*  Privacidad */}
              <Route
                exact
                path={ROUTES.PRIVACIDAD_ROUTE}
                component={scrollToTop(PrivacidadContainer)}
              />
              {/* Metodos Disponibles */}
              <Route
                exact
                path={ROUTES.METODOS_DISPONIBLES_ROUTE}
                component={scrollToTop(MetodosDisponiblesContainer)}
              />
              {/*  Resetear contraseña */}
              <Route
                exact
                path={ROUTES.RESET_PASSWORD_ROUTE}
                component={scrollToTop(ResetPasswordContainer)}
              />
              <Route
                exact
                path={ROUTES.RESET_PASSWORD_SENT_ROUTE}
                component={scrollToTop(ResetPasswordSentContainer)}
              />
              <Route
                exact
                path={ROUTES.RESET_PASSWORD_NEW_PASSWORD_ROUTE}
                component={scrollToTop(NuevaContrasenaContainer)}
              />
              <Route
                exact
                path={ROUTES.RESET_PASSWORD_INVALID_LINK_ROUTE}
                component={scrollToTop(ResetPasswordLinkInvalidoContainer)}
              />
              {/*  Feedback  */}
              <Route
                exact
                path={ROUTES.FEEDBACK_FORM_ROUTE}
                component={scrollToTop(FeedbackContainer)}
              />
              <Route
                exact
                path={ROUTES.ENVIO_FEEDBACK_FORM_SUCCESS}
                component={scrollToTop(FeedbackFormSentContainer)}
              />
              {/*  Videollamada  */}
              <Route
                exact
                path={ROUTES.AGENDAR_VIDEOLLAMADA}
                component={scrollToTop(
                  ciudadanoAutenticado(ContenedorVideollamada, [
                    PERMISO_TRAMITAR_VIDEOLLAMADA,
                  ]),
                )}
              />
              <Route
                exact
                path={ROUTES.VIDEOLLAMADA_CANCELADA}
                component={scrollToTop(
                  ciudadanoAutenticado(CancelarReserva, [
                    PERMISO_TRAMITAR_VIDEOLLAMADA,
                  ]),
                )}
              />
              <Route
                exact
                path={ROUTES.CONFIRMACION_CANCELAR_VIDEOLLAMADA}
                component={scrollToTop(
                  ciudadanoAutenticado(ConfirmarCancelacionReserva, [
                    PERMISO_TRAMITAR_VIDEOLLAMADA,
                  ]),
                )}
              />
              <Route
                exact
                path={ROUTES.INGRESO_VIDEOLLAMADA}
                component={scrollToTop(
                  ciudadanoAutenticado(
                    IngresoVideollamada,
                    [PERMISO_TRAMITAR_VIDEOLLAMADA],
                    condicionPantallaIngresoVideollamada,
                  ),
                )}
              />
              <Route
                exact
                path={ROUTES.INGRESO_VIDEOLLAMADA_SOPORTE}
                component={IngresoVideollamadaSoporte}
              />
              <Route
                exact
                path={ROUTES.CONFIRMAR_ELEVACION_VIDEOLLAMADA}
                component={scrollToTop(
                  ciudadanoAutenticado(AceptarElevacionVideollamada, [
                    PERMISO_TRAMITAR_VIDEOLLAMADA,
                  ]),
                )}
              />
              <Route
                exact
                path={ROUTES.VIDEOLLAMADA_SOPORTE}
                component={scrollToTop(ConfirmacionConsulta)}
              />
              {/*  SAML */}
              <Route
                exact
                path={ROUTES.SAML_RESPONSE_ROUTE}
                component={scrollToTop(SAMLResponseContainer)}
              />
              <Route
                exact
                path={ROUTES.SAML_LOGOUT_ROUTE}
                component={scrollToTop(SAMLLogoutContainer)}
              />
              <Route
                exact
                path={ROUTES.CONFIGURAR_SEGUNDO_FACTOR_LOGIN}
                component={scrollToTop(
                  ciudadanoAutenticado(EnforceSecondFactor),
                )}
              />
              {/*  Pruebas SAML */}
              {window.REACT_APP_SHOW_SAML_INDEX && (
                <Route
                  exact
                  path={ROUTES.SAML_INDEX_ROUTE}
                  component={scrollToTop(
                    !window.REACT_APP_FEATURED_FLAG_REFACTORS_SAML_INDEX_CONTAINER
                      ? SAMLIndexContainerViejo
                      : SAMLIndexContainer,
                  )}
                />
              )}
              {/*  Error */}
              <Route
                exact
                path={ROUTES.ERROR_ROUTE}
                component={scrollToTop(ErrorPageContainer)}
              />
              <Route component={scrollToTop(ErrorPageContainer)} />
            </Switch>
          </div>
          <FooterContainer />
        </section>
      </ConnectedRouter>
    </div>
  </>
);

export default App;
