/* eslint-disable default-case */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import show_password from 'assets/icons/show_password.svg';
import hide_password from 'assets/icons/hide-password.svg';

import {
  DATO_REQUERIDO,
  CONTRASENAS_DEBEN_SER_IGUALES,
} from 'constants/commonConstants';
import styles from './styles.module.css';

export const PasswordField = ({
  register,
  name,
  label,
  required,
  matchValue,
  error,
  showSuccess,
  addInvalidField,
  removeInvalidField,
}) => {
  const [claseLabel, setClaseLabel] = useState('form-field__label');
  const [claseInput, setClaseInput] = useState('form-field__input');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [imgButton, setImgButton] = useState(show_password);

  const validateMatch = value => value === matchValue;

  const validations = {
    required,
    validate: validateMatch,
  };

  const togglePassword = () => {
    setImgButton(showPassword ? show_password : hide_password);
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (error) {
      if (addInvalidField) addInvalidField(name);
      setClaseLabel('form-field__label--error');
      setClaseInput('form-field__input--error');

      switch (error.type) {
        case 'required':
          setErrorMessage(DATO_REQUERIDO);
          break;
        case 'validate':
          setErrorMessage(CONTRASENAS_DEBEN_SER_IGUALES);
          break;
      }
    } else {
      if (removeInvalidField) removeInvalidField(name);
      setClaseInput(
        showSuccess ? 'form-field__input--exito' : 'form-field__input',
      );
      setClaseLabel('form-field__label');
    }
  }, [error, showSuccess]);

  return (
    <div className={styles['form-field']}>
      <span>
        <label className={styles[claseLabel]} htmlFor={name}>
          {label}
        </label>
      </span>
      <div className={styles['form-field__input-password__container']}>
        <input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register(name, validations)}
          type={showPassword ? 'text' : 'password'}
          id={name}
          className={styles[claseInput]}
        />
        {!(showSuccess && !error) && (
          <button
            type="button"
            className={styles['form-field__input-password__button']}
            onClick={togglePassword}
          >
            <img alt="Mostrar contraseña" src={imgButton} />
          </button>
        )}
      </div>
      {error && (
        <div className={styles['form-field__help-text__error__div']}>
          <span className={styles['form-field__help-text__error']}>
            {errorMessage}
          </span>
        </div>
      )}
    </div>
  );
};

PasswordField.propTypes = {
  register: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  matchValue: PropTypes.string,
  error: PropTypes.object,
  showSuccess: PropTypes.bool,
  addInvalidField: PropTypes.func,
  removeInvalidField: PropTypes.func,
};

export default PasswordField;
