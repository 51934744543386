export const cargandoUsuarioLogueadoSelector = state =>
  state.mis_datos?.loading;
export const usuarioLogueadoSelector = state => state.mis_datos?.currentUser;
export const usuarioLogueadoEstadoSelector = state => state.mis_datos?.estado;
export const isAuthenticatedSelector = state => state.auth.isAuthenticated;
export const cargandoEmailPorValidarSelector = state =>
  state.mis_datos?.loadingEmailPorValidar;
export const emailPorValidarSelector = state =>
  state.mis_datos?.currentUser?.email_por_validar?.email;
export const totpSelector = state => state.mfa.methods.totp;

// seguridad
export const verifiedPasswordSelector = state =>
  state.seguridad.verifiedPassword;
export const verifiedPasswordErrorsSelector = state =>
  state.seguridad.verifiedPasswordErrors;
export const seguridadCargandoSelector = state => state.seguridad.loading;
export const configuringSecondFactorSelector = state =>
  state.seguridad.configuringSecondFactor;
export const redirectUriPostSecondFactorSelector = state =>
  state.seguridad.redirectUriPostSecondFactor;

// Videollamada
export const obtenerPermisosCiudadanoSelector = state =>
  state.mis_datos?.currentUser?.user?.permisos || {};

// Header
export const mostrarHeaderSelector = state => state.ui.header.showHeader;
export const locationSelector = state => state.router.location.pathname;

// SAML
export const logoutRequestsSelector = state => state.brokerSaml.logoutRequests;
export const waitLongerSelector = state => state.brokerSaml.waitLonger;
export const authnResponseSelector = state => state.brokerSaml.authnResponse;
export const brokerSamlActionSelector = state => state.brokerSaml.action;
export const relayStateSelector = state => state.brokerSaml.relayState;
