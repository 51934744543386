/* eslint-disable react/button-has-type */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/prop-types */
/* eslint-disable max-classes-per-file */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { fetchMisDatos } from 'actions/misDatos';
import * as ROUTES from 'constants/RouterConstants';
import {
  HEADER_LOGIN_TEXT,
  HEADER_LOGIN_ARIA_LABEL,
  HEADER_REGISTRO_ARIA_LABEL,
  HEADER_REGISTRO_TEXT,
  HEADER_GUB_UY,
  HEADER_ID_URUGUAY,
} from 'constants/commonConstants';
import ButtonLink from '../ButtonLink';
import ButtonMenuNavbar from '../ButtonMenuNavbar';

import './styles.css';

const InternalOptIn = ({
  isMenuOpen,
  showMoreOptionsTooltip,
  handleOpenMenu,
  handleOpenTooltip,
  handleGoSeguridad,
  handleCerrarSesion,
  registerClosableNode,
  registerClosableButton,
  registerClosableResponsive,
  username,
  nivel,
  showSeguridadButton,
}) => (
  <div className="header-internal-opt-in">
    <ButtonMenuNavbar
      isMenuOpen={isMenuOpen}
      handleOpenMenu={handleOpenMenu}
      ariaExpanded={isMenuOpen ? 'true' : 'false'}
      ariaHasPopup="true"
      ariaControls="mobile-menu"
      username={username}
      refButtonMenuNavbar={registerClosableResponsive}
      classScreenSize="hidden-desktop"
      // menu buttons
      refButtonFlat={registerClosableNode}
      showSeguridadButton={showSeguridadButton}
      handleCerrarSesion={handleCerrarSesion}
      handleGoSeguridad={handleGoSeguridad}
      nivel={nivel}
    />
    <ButtonMenuNavbar
      isMenuOpen={showMoreOptionsTooltip}
      handleOpenMenu={handleOpenTooltip}
      ariaExpanded={showMoreOptionsTooltip ? 'true' : 'false'}
      ariaHasPopup="true"
      ariaControls="desktop-menu"
      username={username}
      refButtonMenuNavbar={registerClosableButton}
      classScreenSize="hidden-tablet"
      // menu buttons
      refButtonFlat={registerClosableNode}
      showSeguridadButton={showSeguridadButton}
      handleCerrarSesion={handleCerrarSesion}
      handleGoSeguridad={handleGoSeguridad}
      nivel={nivel}
    />
  </div>
);

const ExternalOptOut = ({ location }) => (
  <div className="header-external-opt-out">
    {/* Cualquier otro flujo de ExternalOptOut */}
    {location !== ROUTES.REGISTRO_ROUTE &&
      location !== ROUTES.SEGURIDAD_ROUTE &&
      location !== ROUTES.METODOS_DISPONIBLES_ROUTE &&
      !location.includes(ROUTES.LOGIN_ROUTE) && (
        <div className="header-buttons">
          <div>
            <ButtonLink
              buttonType="header"
              to={ROUTES.LOGIN_ROUTE}
              aria-label={HEADER_LOGIN_ARIA_LABEL}
              title={HEADER_LOGIN_TEXT}
            />
          </div>
          <div>
            <ButtonLink
              buttonType="header"
              style={{ marginLeft: '16px' }}
              to={ROUTES.REGISTRO_ROUTE}
              aria-label={HEADER_REGISTRO_ARIA_LABEL}
              title={HEADER_REGISTRO_TEXT}
            />
          </div>
        </div>
      )}
  </div>
);

class Header extends React.Component {
  // --------------------------------------------------------------------------
  // Inicialización
  // --------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      isMenuOpen: false,
      showMoreOptionsTooltip: false,
      currentUser: null,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleGoToRegistro = this.handleGoToRegistro.bind(this);
    this.handleGoToLogin = this.handleGoToLogin.bind(this);
    this.handleGoToLogout = this.handleGoToLogout.bind(this);
    this.handleGoSeguridad = this.handleGoSeguridad.bind(this);
    this.handleGoHome = this.handleGoHome.bind(this);
    this.handleOpenMenu = this.handleOpenMenu.bind(this);
    this.handleOpenTooltip = this.handleOpenTooltip.bind(this);
    this.toggleOverlay = this.toggleOverlay.bind(this);

    this.registerClosableNode = this.registerClosableNode.bind(this);
    this.registerClosableButton = this.registerClosableButton.bind(this);
    this.registerClosableResponsive =
      this.registerClosableResponsive.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
    window.removeEventListener('popstate', this.closeDropdown);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
    window.addEventListener('popstate', this.closeDropdown);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.currentUser !== prevState.currentUser) {
      return { currentUser: nextProps.currentUser };
    }
    if (!nextProps.isAuthenticated !== prevState.isExternalOptOut) {
      return { isExternalOptOut: !nextProps.isAuthenticated };
    }
    if (
      (nextProps.isAuthenticated && !nextProps.showInternalHeader) !==
      prevState.isExternalOptIn
    ) {
      return {
        isExternalOptIn:
          nextProps.isAuthenticated && !nextProps.showInternalHeader,
      };
    }
    if (
      (nextProps.isAuthenticated && nextProps.showInternalHeader) !==
      prevState.isInternalOptIn
    ) {
      return {
        isInternalOptIn:
          nextProps.isAuthenticated && nextProps.showInternalHeader,
      };
    }
    return null;
  }

  // --------------------------------------------------------------------------
  // Funciones
  // --------------------------------------------------------------------------
  registerClosableNode(node) {
    this.closableNone = node;
  }

  registerClosableButton(button) {
    this.closableButton = button;
  }

  registerClosableResponsive(button) {
    this.closableButtonResponsive = button;
  }

  // --------------------------------------------------------------------------
  // Eventos
  // --------------------------------------------------------------------------
  handleClick(e) {
    if (
      (this.closableNone && this.closableNone.contains(e.target)) ||
      (this.closableButton && this.closableButton.contains(e.target)) ||
      (this.closableButtonResponsive &&
        this.closableButtonResponsive.contains(e.target))
    ) {
      return;
    }
    // Click outside of tooltip
    this.setState(
      {
        showMoreOptionsTooltip: false,
        isMenuOpen: false,
      },
      () => {
        this.toggleOverlay();
      },
    );
  }

  toggleOverlay() {
    const overlay = document.getElementById('overlay');
    if (overlay) {
      if (this.state.isMenuOpen) {
        overlay.className = 'overlay--visible';
      } else {
        overlay.className = 'overlay--hidden';
      }
    }
  }

  handleGoToRegistro() {
    this.props.history.push(ROUTES.REGISTRO_ROUTE);
  }

  handleGoToLogin() {
    this.props.history.push(ROUTES.LOGIN_ROUTE);
  }

  handleGoToLogout() {
    this.props.history.push(ROUTES.LOGOUT_PROCESS);
  }

  handleGoSeguridad() {
    this.props.history.push(ROUTES.SEGURIDAD_ROUTE);
    this.setState({ isMenuOpen: false, showMoreOptionsTooltip: false });
  }

  handleGoHome() {
    window.location.href = ROUTES.HOME_ROUTE;
  }

  handleOpenMenu() {
    this.setState({ isMenuOpen: !this.state.isMenuOpen }, () => {
      this.toggleOverlay();
    });
  }

  handleOpenTooltip() {
    this.setState({
      showMoreOptionsTooltip: !this.state.showMoreOptionsTooltip,
    });
  }

  getUserName = () =>
    this.state.currentUser ? `${this.state.currentUser.nombre_completo}` : null;

  closeDropdown = () => {
    this.setState({ isMenuOpen: false, showMoreOptionsTooltip: false });
  };

  // --------------------------------------------------------------------------
  // Renderización
  // --------------------------------------------------------------------------
  render() {
    const username = this.getUserName();
    return this.props.showHeader ? (
      <>
        <div id="overlay"></div>
        <header role="banner">
          <div className="header-wrapper">
            <div className="header-wrapper__left">
              <button
                className="focus-style"
                onClick={this.handleGoHome}
                aria-label={HEADER_GUB_UY}
              >
                <span className="header-wrapper__left__logo Brand-text">
                  {HEADER_GUB_UY}
                </span>
              </button>
              {!this.props.isAuthenticated && (
                <>
                  <p className="header-wrapper__left__pipe">|</p>
                  <p className="header-wrapper__left__texto">
                    {HEADER_ID_URUGUAY}
                  </p>
                </>
              )}
            </div>
            <div className="header-wrapper__right">
              {this.state.isExternalOptOut ? (
                <ExternalOptOut location={this.props.location} />
              ) : (
                <InternalOptIn
                  isMenuOpen={this.state.isMenuOpen}
                  showMoreOptionsTooltip={this.state.showMoreOptionsTooltip}
                  handleOpenMenu={this.handleOpenMenu}
                  handleOpenTooltip={this.handleOpenTooltip}
                  handleGoSeguridad={this.handleGoSeguridad}
                  handleCerrarSesion={this.handleGoToLogout}
                  registerClosableNode={this.registerClosableNode}
                  registerClosableButton={this.registerClosableButton}
                  registerClosableResponsive={this.registerClosableResponsive}
                  username={username}
                  nivel={
                    this.state.currentUser ? this.state.currentUser.nivel : null
                  }
                  showSeguridadButton={this.state.isExternalOptIn}
                />
              )}
            </div>
          </div>
        </header>
      </>
    ) : null;
  }
}

Header.propTypes = {
  history: PropTypes.object,
  location: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  showHeader: PropTypes.bool,
  showInternalHeader: PropTypes.bool,
  currentUser: PropTypes.object,
};

const HeaderContainer = ({
  history,
  location,
  isAuthenticated,
  showHeader,
  showInternalHeader,
  currentUser,
}) => (
  <Header
    history={history}
    location={location}
    isAuthenticated={isAuthenticated}
    showHeader={showHeader}
    showInternalHeader={showInternalHeader}
    currentUser={currentUser}
  />
);

const mapStateToProps = state => ({
  showInternalHeader: state.ui.header.showInternalHeader,
  showHeader: state.ui.header.showHeader,
  isAuthenticated: state.auth.isAuthenticated,
  currentUser: state.mis_datos.currentUser,
  location: state.router.location.pathname,
});

export default connect(mapStateToProps, { fetchMisDatos })(HeaderContainer);
