import React from 'react';

import {
  HEADER_LOGIN_TEXT,
  HEADER_REGISTRO_ARIA_LABEL,
  HEADER_REGISTRO_TEXT,
} from 'constants/commonConstants';
import {
  LOGIN_ROUTE,
  METODOS_DISPONIBLES_ROUTE,
  REGISTRO_ROUTE,
  SEGURIDAD_ROUTE,
} from 'constants/RouterConstants';

import { useSelector } from 'react-redux';
import { locationSelector } from 'store/selectors';
import ButtonLink from '../../ButtonLink';

import styles from './styles.module.css';

const rutasAExcluir = [
  REGISTRO_ROUTE,
  SEGURIDAD_ROUTE,
  METODOS_DISPONIBLES_ROUTE,
  LOGIN_ROUTE,
];

const HeaderMenuExterno = () => {
  const location = useSelector(locationSelector);

  return (
    <div className={styles['header-external-opt-out']}>
      {!rutasAExcluir.includes(location) && (
        <div className={styles.botonesHeader}>
          <div>
            <ButtonLink
              buttonType="header"
              to={LOGIN_ROUTE}
              aria-label={HEADER_LOGIN_TEXT}
              title={HEADER_LOGIN_TEXT}
            />
          </div>
          <div>
            <ButtonLink
              buttonType="header"
              to={REGISTRO_ROUTE}
              aria-label={HEADER_REGISTRO_ARIA_LABEL}
              title={HEADER_REGISTRO_TEXT}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderMenuExterno;
