import { push } from 'connected-react-router';
import {
  CHANGE_PASSWORD_ROUTE_SUCCESS,
  CHANGE_USER_EMAIL_ERROR_ROUTE,
  CHANGE_USER_EMAIL_VALIDATED_ROUTE,
  MFA_CONFIG_TOTP_ROUTE,
  MFA_PAGE_ROUTE,
  MFA_VERIFY_CODE_SUCCESS_TOTP_ROUTE,
  REGISTRO_ACTIVATION_ERROR,
  REGISTRO_ACTIVATION_SUCCESS,
} from './RouterConstants';

// ---------------------------------------------------------------------------
// Base Action Type
// ---------------------------------------------------------------------------
const asyncActionType = (
  type,
  extraPendingActions = [],
  extraSuccessActions = [],
  extraErrorActions = [],
) => ({
  PENDING: `${type}_PENDING`,
  SUCCESS: `${type}_SUCCESS`,
  ERROR: `${type}_ERROR`,
  EXTRA_PENDING: extraPendingActions,
  EXTRA_SUCCESS: extraSuccessActions,
  EXTRA_ERROR: extraErrorActions,
});

// ---------------------------------------------------------------------------
// Info del ciudadano
// ---------------------------------------------------------------------------
export const FETCH_MIS_DATOS = asyncActionType('FETCH_MIS_DATOS');
export const FETCH_EMAIL_POR_VALIDAR = asyncActionType(
  'FETCH_EMAIL_POR_VALIDAR',
);
export const SET_EMAIL_POR_VALIDAR = 'SET_EMAIL_POR_VALIDAR';
export const SET_ULTIMO_CAMBIO_PASSWORD = 'SET_ULTIMO_CAMBIO_PASSWORD';
export const FETCH_FAQS = asyncActionType('FETCH_FAQS');
export const RESEND_ACTIVATION_EMAIL_LOGGED = asyncActionType(
  'RESEND_ACTIVATION_EMAIL_LOGGED',
);
export const RESET_RESEND_ACTIVATION_EMAIL_LOGGED =
  'RESET_RESEND_ACTIVATION_EMAIL_LOGGED';

// ---------------------------------------------------------------------------
// Update info
// ---------------------------------------------------------------------------
export const PASSWORD_CHANGE_PROCESS = asyncActionType(
  'PASSWORD_CHANGE_PROCESS',
);
export const RESET_PASSWORD_CHANGE_PROCESS = 'RESET_PASSWORD_CHANGE_PROCESS';
export const CHANGE_USER_PASSWORD = asyncActionType(
  'CHANGE_USER_PASSWORD',
  [PASSWORD_CHANGE_PROCESS.PENDING],
  [PASSWORD_CHANGE_PROCESS.SUCCESS, RESET_PASSWORD_CHANGE_PROCESS],
  [PASSWORD_CHANGE_PROCESS.ERROR],
);

export const SET_AUTH_WITH_PASSWORD = 'SET_AUTH_WITH_PASSWORD';
export const UNSET_AUTH_WITH_PASSWORD = 'UNSET_AUTH_WITH_PASSWORD';
export const CLEAR_PASSWORD_VERIFICATION_ERRORS =
  'CLEAR_PASSWORD_VERIFICATION_ERRORS';
export const CLEAR_PASSWORD_CHANGE_ERRORS = 'CLEAR_PASSWORD_CHANGE_ERRORS';
export const VERIFIED_USER_PASSOWRD = asyncActionType('VERIFIED_USER_PASSOWRD');

// email
export const CLEAR_EMAIL_UPDATE_ERRORS = 'CLEAR_EMAIL_UPDATE_ERRORS';
export const EMAIL_CHANGE_PROCESS = asyncActionType('EMAIL_CHANGE_PROCESS');
export const RESET_EMAIL_CHANGE_PROCESS = 'RESET_EMAIL_CHANGE_PROCESS';
export const CHANGE_USER_EMAIL_REQUEST = asyncActionType(
  'CHANGE_USER_EMAIL_REQUEST',
  [EMAIL_CHANGE_PROCESS.PENDING],
  [EMAIL_CHANGE_PROCESS.SUCCESS, RESET_EMAIL_CHANGE_PROCESS],
  [EMAIL_CHANGE_PROCESS.ERROR],
);
export const APPROVE_EMAIL_CHANGE = asyncActionType(
  'APPROVE_EMAIL_CHANGE',
  [],
  [push(CHANGE_USER_EMAIL_VALIDATED_ROUTE)],
  [push(CHANGE_USER_EMAIL_ERROR_ROUTE)],
);

// ---------------------------------------------------------------------------
// UI
// ---------------------------------------------------------------------------
export const SHOW_INTERNAL_HEADER = 'SHOW_INTERNAL_HEADER';
export const SHOW_EXTERNAL_HEADER = 'SHOW_EXTERNAL_HEADER';
export const SHOW_DIGITAL_SIGNATURE_MODAL = 'SHOW_DIGITAL_SIGNATURE_MODAL';
export const HIDE_DIGITAL_SIGNATURE_MODAL = 'HIDE_DIGITAL_SIGNATURE_MODAL';
export const SHOW_HEADER = 'SHOW_HEADER';
export const HIDE_HEADER = 'HIDE_HEADER';
export const SHOW_FOOTER = 'SHOW_FOOTER';
export const HIDE_FOOTER = 'HIDE_FOOTER';

// ---------------------------------------------------------------------------
// Registro
// ---------------------------------------------------------------------------
export const FETCH_PAISES = asyncActionType('FETCH_PAISES');
export const CREATE_CIUDADANO = asyncActionType('CREATE_CIUDADANO');
export const CLEAR_REGISTRO_ERRORS = 'CLEAR_REGISTRO_ERRORS';
export const RESET = 'RESET';
export const RESEND_ACTIVATION = asyncActionType('RESEND_ACTIVATION');
export const ACTIVATE_ACCOUNT = asyncActionType(
  'ACTIVATE_ACCOUNT',
  [],
  [push(REGISTRO_ACTIVATION_SUCCESS)],
  [push(REGISTRO_ACTIVATION_ERROR)],
);

// ---------------------------------------------------------------------------
// Reset Password
// ---------------------------------------------------------------------------
export const CONFIRM_RESET_PASSWORD = asyncActionType(
  'CONFIRM_RESET_PASSWORD',
  [],
  [push(CHANGE_PASSWORD_ROUTE_SUCCESS)],
  [],
);
export const CLEAR_RESET_PASSWORD_ERRORS = 'CLEAR_RESET_PASSWORD_ERRORS';

// ---------------------------------------------------------------------------
// ARIA
// ---------------------------------------------------------------------------
export const UPDATE_ARIA_LIVE_MESSAGE = 'UPDATE_ARIA_LIVE_MESSAGE';

// ---------------------------------------------------------------------------
// BROKER - OIDC
// ---------------------------------------------------------------------------
export const FETCH_APLICACIONES_CON_CONSENTIMIENTO = asyncActionType(
  'FETCH_APLICACIONES_CON_CONSENTIMIENTO',
);
export const REVOCAR_CONSENTIMIENTO_APLICACION_PROCESS = asyncActionType(
  'REVOCAR_CONSENTIMIENTO_APLICACION_PROCESS',
);
export const REVOCAR_CONSENTIMIENTO_APLICACION = asyncActionType(
  'REVOCAR_CONSENTIMIENTO_APLICACION',
  [REVOCAR_CONSENTIMIENTO_APLICACION_PROCESS.PENDING],
  [REVOCAR_CONSENTIMIENTO_APLICACION_PROCESS.SUCCESS],
  [REVOCAR_CONSENTIMIENTO_APLICACION_PROCESS.ERROR],
);
export const RESET_CONSENTIMIENTO_REVOCADO = 'RESET_CONSENTIMIENTO_REVOCADO';
export const CLIENT_DETAIL = asyncActionType('CLIENT_DETAIL');
export const ACCEPT_CONSENT = asyncActionType('ACCEPT_CONSENT');

// ---------------------------------------------------------------------------
// Federated Identity
// ---------------------------------------------------------------------------
export const FETCH_IDPS = asyncActionType('FETCH_IDPS');
export const FETCH_MIS_DATOS_IDP = asyncActionType('FETCH_MIS_DATOS_IDP');

// ---------------------------------------------------------------------------
// MFA
// ---------------------------------------------------------------------------
export const ACTIVATE_TOTP = asyncActionType(
  'ACTIVATE_TOTP',
  [],
  [push(MFA_CONFIG_TOTP_ROUTE)],
  [],
);

export const VERIFY_CODE_TOTP = asyncActionType(
  'VERIFY_CODE_TOTP',
  [],
  [push(MFA_VERIFY_CODE_SUCCESS_TOTP_ROUTE)],
  [],
);

export const MFA_RESET = 'MFA_RESET';
export const FETCH_MFA = asyncActionType('FETCH_MFA');
export const DELETE_TOTP = asyncActionType(
  'DELETE_TOTP',
  [],
  [push(MFA_PAGE_ROUTE)],
  [],
);

export const RESEND_RECOVERY_CODE_EMAIL = asyncActionType(
  'RESEND_RECOVERY_CODE_EMAIL',
);

// ---------------------------------------------------------------------------
// Broker SAML
// ---------------------------------------------------------------------------
export const AUTHN_RESPONSE = 'AUTHN_RESPONSE';
export const FETCH_LOGOUT_REQUESTS = asyncActionType('FETCH_LOGOUT_REQUESTS');
export const FETCH_LOGOUT_RESPONSE = asyncActionType('FETCH_LOGOUT_RESPONSE');
export const SET_LOGOUT_REQUESTS = 'SET_LOGOUT_REQUESTS';

// ---------------------------------------------------------------------------
// PAGINA ESTATICA
// ---------------------------------------------------------------------------
export const FETCH_PAGINA_ESTATICA = asyncActionType('FETCH_PAGINA_ESTATICA');

// ---------------------------------------------------------------------------
// Segundo factor post login
// ---------------------------------------------------------------------------
export const SET_ESTA_CONFIGURANDO_FACTOR = 'SET_ESTA_CONFIGURANDO_FACTOR';
export const SEGUNDO_FACTOR_CONFIGURADO = 'SEGUNDO_FACTOR_CONFIGURADO';
