import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import _camelCase from 'lodash/camelCase';

import {
  getMensajeErrorLargoMaximo,
  getMensajeErrorLargoMinimo,
  IDENTIFICATE,
  NUMERO_DOCUMENTO,
  NUMERO_REGISTRADO,
  DATO_REQUERIDO,
  ERROR_BACKEND_CIUDADANO_INHABILITADO_SERVICIO_EXTERNO,
  ERROR_BACKEND_CIUDADANO_DATOS_DISTINTOS_SERVICIO_EXTERNO,
  ERROR_CIUDADANO_DATOS_DISTINTOS_SERVICIO_EXTERNO,
} from 'constants/commonConstants';

import { clearRegistroErrors } from 'actions/registro';
import styles from './styles.module.css';
import ErrorHelpText from './ErrorHelpText';

export const TextField = ({
  register,
  trigger,
  name,
  small,
  type,
  value,
  label,
  required,
  showAlert,
  error,
  touched,
  registroErrors,
  placeholder,
  minLength,
  maxLength,
  validationRegex,
  regexErrorMessage,
  validateDocument,
  validationErrorMessage,
  matchValue,
  showSuccess,
  addInvalidField,
  removeInvalidField,
  isDisabled,
  registroForm,
}) => {
  const clase = small ? 'form-field__input--small' : 'form-field__input';
  const [claseInput, setClaseInput] = useState(clase);
  const [claseLabel, setClaseLabel] = useState('form-field__label');
  const [validationError, setValidationError] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const validateMatch = valueToMatch => valueToMatch === matchValue;

  // eslint-disable-next-line consistent-return
  const getValidationFunction = () => {
    switch (name) {
      case 'repetirEmail':
        return validateMatch;
      case 'numeroDocumento':
        return validateDocument;
      default:
    }
  };

  const validations = {
    required,
    minLength,
    maxLength,
    pattern: validationRegex,
    validate: getValidationFunction(),
  };

  useEffect(() => {
    if (registroErrors) {
      const registroError = registroErrors.error;
      const registroErrorField = registroErrors.field;
      if (registroError && _camelCase(registroErrorField) === name) {
        setSubmitError(true);
        setErrorMessage(registroError);
        if (registroErrorField === 'numero_documento') {
          switch (registroError) {
            case ERROR_BACKEND_CIUDADANO_DATOS_DISTINTOS_SERVICIO_EXTERNO:
              showAlert(true, ERROR_CIUDADANO_DATOS_DISTINTOS_SERVICIO_EXTERNO);
              break;
            case ERROR_BACKEND_CIUDADANO_INHABILITADO_SERVICIO_EXTERNO:
              showAlert(
                true,
                ERROR_BACKEND_CIUDADANO_INHABILITADO_SERVICIO_EXTERNO,
              );
              break;
            default:
              showAlert(true, NUMERO_REGISTRADO, IDENTIFICATE);
          }
          setErrorMessage('Error');
        }
      }
    } else {
      setSubmitError(false);
      setErrorMessage('');
      if (showAlert) showAlert(false, '', '');
    }
  }, [registroErrors]);

  useEffect(() => {
    if (error) {
      setValidationError(true);
      if (addInvalidField) addInvalidField(name);
      setClaseLabel('form-field__label--error');
      // eslint-disable-next-line no-unused-expressions
      name === NUMERO_DOCUMENTO
        ? setClaseInput('form-field__input--small--error')
        : setClaseInput('form-field__input--error');
      switch (error.type) {
        case 'required':
          setErrorMessage(DATO_REQUERIDO);
          break;
        case 'minLength':
          setErrorMessage(getMensajeErrorLargoMinimo(value));
          break;
        case 'maxLength':
          setErrorMessage(getMensajeErrorLargoMaximo(maxLength));
          break;
        case 'pattern':
          setErrorMessage(regexErrorMessage);
          break;
        case 'validate':
          setErrorMessage(validationErrorMessage);
          break;
        default:
      }
    } else {
      if (removeInvalidField) removeInvalidField(name);
      if (clearRegistroErrors) clearRegistroErrors();
      setSubmitError(false);
      if (showAlert) showAlert(false, '', '');
      setClaseLabel(`form-field__label`);
      setValidationError(false);
      // eslint-disable-next-line no-shadow
      let claseInput = 'form-field__input';
      // los estilos de small y exito son disjuntos
      if (small) claseInput += '--small';
      if (showSuccess) claseInput += '--exito';
      setClaseInput(claseInput);
    }
  }, [error, showSuccess]);

  useEffect(() => {
    if (touched && name === 'email') trigger('repetirEmail');
  }, [value]);

  return (
    <div className={styles['form-field']}>
      <span>
        <label className={styles[claseLabel]} htmlFor={name}>
          {label}
        </label>
        {required && registroForm && (
          <span className={styles['form-field__label--required']}>
            {' '}
            (Obligatorio)
          </span>
        )}
        {!required && (
          <span className={styles['form-field__label--required']}>
            {' '}
            (Opcional)
          </span>
        )}
      </span>
      <span>
        <input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register(name, validations)}
          className={styles[claseInput]}
          type={type}
          placeholder={placeholder}
          id={name}
          disabled={isDisabled}
          required={required}
        />
      </span>

      <ErrorHelpText
        error={validationError || submitError}
        msg={errorMessage}
        small={small}
      />
    </div>
  );
};

TextField.propTypes = {
  register: PropTypes.func.isRequired,
  trigger: PropTypes.func,
  touched: PropTypes.bool,
  name: PropTypes.string.isRequired,
  small: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  showAlert: PropTypes.func,
  error: PropTypes.object,
  registroErrors: PropTypes.object,
  placeholder: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  validationRegex: PropTypes.oneOfType([
    PropTypes.shape(RegExp),
    PropTypes.bool,
  ]),
  regexErrorMessage: PropTypes.string,
  validateDocument: PropTypes.func,
  validationErrorMessage: PropTypes.string,
  matchValue: PropTypes.string,
  showSuccess: PropTypes.bool,
  addInvalidField: PropTypes.func,
  removeInvalidField: PropTypes.func,
  isDisabled: PropTypes.bool,
  registroForm: PropTypes.bool,
};

export default TextField;
